import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 text-center">
            <div className="app-logo">
              <Link to="/">
                <img src="logo-new.png" alt="" />
              </Link>
            </div>
            <div className="btn-group">
              <a
                href="https://play.google.com/store/apps/details?id=com.hudderfieldprayer&hl=en_GB&gl=US"
                className="store-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="google-play.svg" alt="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/id1561216898"
                className="store-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="apple-store.svg" alt="" />
              </a>
            </div>
            <div className="copyright-text">
              <p>Huddersfield Prayer Times © . All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
