import { db } from "../firebase.config";
import React, { useState, useEffect } from "react";
import Calendar from "./Calendar";
import "./Section5.css";
import Loader from "./Loader";

const Section5 = (props) => {
  const isLoading = props.isLoading;
  let mosqueName = props.mosqueInfo["Name"];
  let mosqueId = props.mosqueInfo["id"];
  let checkDate = props.checkDate;

  const [monthPrayerData, setMonthPrayerData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const [routeChange, setRouteChange] = useState(false);
  const [calendarValue, setCalendarValue] = useState("");

  const calendarPlaceHolder = props.thisMonth + " " + props.year;

  //select and set month value
  const handleCalendarChange = (element, currentValue) => {
    //this condition is executed only once when the application gets started in order to show the current month data
    if (routeChange === true) {
      var today = new Date();
      var month = today.getMonth() + 1;
      var year = today.getFullYear();

      if (month < 10) {
        month = "0" + month;
      }

      var currentMonthYear = year + "-" + month;
      currentValue = currentMonthYear;
      setCalendarValue(currentValue);
      setRouteChange(false);
    } else {
      setCalendarValue(currentValue);
    }
  };

  //used to change format of the date
  function changeFormat(viewDate) {
    let format = viewDate;
    format = format.trim();
    let formattedValue = format.split("-");

    let day = formattedValue[2];
    let month = formattedValue[1];

    var singleNumberMonth = month;

    if (singleNumberMonth < 10) {
      singleNumberMonth = month.slice(1, 2);
    }

    singleNumberMonth = singleNumberMonth - 1;

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let wordMonth = months[singleNumberMonth];

    let year = formattedValue[0];

    var completeDate = wordMonth + " " + day + ", " + year;

    completeDate = completeDate.toString();

    var dt = new Date(completeDate);

    var weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var weekDay = weekDays[dt.getDay()];

    let finalValue = weekDay + " " + day + "  " + wordMonth;

    return finalValue;
  }

  //get namaz timings of a month
  const showNamazTimings = async (calendarValue) => {
    setIsLoad(true);

    const myArray = calendarValue.split("-");
    const month = myArray[1];
    const year = myArray[0];

    const daysInMonth = new Date(year, month, 0).getDate();

    const startDate = year + "-" + month + "-01";
    const endDate = year + "-" + month + "-" + daysInMonth;

    //     console.log('start : ' + startDate + '/' + 'end : ' + endDate );

    const prayerTimes = await db
      .collection("Mosques")
      .doc(mosqueId)
      .collection("Prayer Times");

    const filteredPrayertimes = [];

    prayerTimes.get().then((querySnapshot) => {
      querySnapshot.docs.map((doc) => {
        if (doc.id >= startDate && doc.id <= endDate) {
          filteredPrayertimes.push({ date: doc.id, ...doc.data() });
        }
        return { date: doc.id, ...doc.data() };
      });
      // console.log(filteredPrayertimes);
      setMonthPrayerData(filteredPrayertimes);
      setIsLoad(false);
    });
  };

  //shows complete month namaz timings whenever month state is changed
  useEffect(() => {
    //handleCalendarChange();
    showNamazTimings(calendarValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarValue]);

  useEffect(() => {
    //getting current year and month
    var today = new Date();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    if (month < 10) {
      month = "0" + month;
    }

    var currentMonthYear = year + "-" + month;

    //calling this function with current month and year whenever we switch between different mosques
    showNamazTimings(currentMonthYear);
    setRouteChange(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosqueId]);

  //it is used to set this state to false when application is visited first time
  useEffect(() => {
    setRouteChange(false);
  }, []);

  return (
    <>
      <section className="section-5">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-9 col-xl-12 mx-auto">
              {isLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <div className="card hpt-content-card">
                  <div className="card-header">
                    <h2 className="title fw-bold">
                      Prayer Timetable: {mosqueName}
                    </h2>
                    <span className="today-date fw-bold">
                      <label htmlFor="Select Month" hidden>
                        <b>Select Month</b>
                      </label>
                      <Calendar
                        options={{
                          value: calendarValue,
                          onchange: handleCalendarChange,
                          type: "year-month-picker",
                          format: "MMMM YYYY",
                          readonly: false,
                          placeholder: calendarPlaceHolder,
                        }}
                      />
                    </span>
                  </div>
                  <div className="card-body">
                    {isLoad ? (
                      <Loader />
                    ) : (
                      monthPrayerData.length > 0 && (
                        <div className="table-responsive">
                          <table className="table table-secondary table-bordered table-striped ">
                            <thead className="text-center table-dark">
                              <tr>
                                <th
                                  scope="col"
                                  rowSpan={2}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Date
                                </th>
                                <th scope="col" colSpan={2}>
                                  Fajr
                                </th>
                                <th
                                  scope="col"
                                  rowSpan={2}
                                  style={{ verticalAlign: "middle" }}
                                >
                                  Sunrise
                                </th>
                                <th scope="col" colSpan={2}>
                                  Dhuhr
                                </th>
                                <th scope="col" colSpan={2}>
                                  Asr
                                </th>
                                <th scope="col" colSpan={2}>
                                  Maghrib
                                </th>
                                <th scope="col" colSpan={2}>
                                  Isha
                                </th>
                              </tr>
                              <tr>
                                <th>Start</th>
                                <th>Jammat</th>
                                <th>Start</th>
                                <th>Jammat</th>
                                <th>Start</th>
                                <th>Jammat</th>
                                <th>Start</th>
                                <th>Jammat</th>
                                <th>Start</th>
                                <th>Jammat</th>
                              </tr>
                            </thead>
                            <tbody className="text-center">
                              {monthPrayerData.map((day) => {
                                let activeDay = "";

                                if (day["date"] === checkDate) {
                                  activeDay = "activeDay";
                                } else {
                                  activeDay = "";
                                }

                                return (
                                  <tr key={day["date"]}>
                                    <td className={activeDay}>
                                      {changeFormat(day["date"])}
                                    </td>
                                    <td className={activeDay}>{day["fajr"]}</td>
                                    <td className={activeDay}>
                                      {day["fajrJamaat"]}
                                    </td>
                                    <td className={activeDay}>
                                      {day["sunrise"]}
                                    </td>
                                    <td className={activeDay}>
                                      {day["dhuhr"]}
                                    </td>
                                    <td className={activeDay}>
                                      {day["dhuhrJamaat"]}
                                    </td>
                                    <td className={activeDay}>{day["asr"]}</td>
                                    <td className={activeDay}>
                                      {day["asrJamaat"]}
                                    </td>
                                    <td className={activeDay}>
                                      {day["maghrib"]}
                                    </td>
                                    <td className={activeDay}>
                                      {day["maghribJamaat"]
                                        ? day["maghribJamaat"]
                                        : "--"}
                                    </td>
                                    <td className={activeDay}>{day["isha"]}</td>
                                    <td className={activeDay}>
                                      {day["ishaJamaat"]}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section5;
