import React, { useState, useEffect } from "react";
import "./Section4.css";

const Section4 = (props) => {
  let mosqueId = props.mosqueInfo["id"];

  const [mapString, setMapString] = useState("");

  useEffect(() => {
    //making th location map parameters
    let contact1 = props.mosqueInfo["Address Line 1"];
    contact1 = contact1.trim();
    let contact1Array = contact1.split(" ");
    let contact1String = contact1Array.join("%20");

    let contact2 = props.mosqueInfo["Address Line 2"];
    contact2 = contact2.trim();
    let contact2Array = contact2.split(" ");
    let contact2String = contact2Array.join("%20");

    let city = props.mosqueInfo["City"];
    city = city.trim();
    let cityArray = city.split(" ");
    let cityString = cityArray.join("%20");

    let FinalLocationString =
      contact1String + "%20" + contact2String + "%20" + cityString;

    setMapString(FinalLocationString);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosqueId]);

  return (
    <>
      <section className="section-4">
        <div className="card hpt-content-card">
          <div className="card-header">
            <h2 className="title fw-bold">Address & Location</h2>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-xl-8">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      width="100%"
                      height="570"
                      id="gmap_canvas"
                      src={
                        "https://maps.google.com/maps?q=" +
                        mapString +
                        "&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      }
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      title="masjid"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="address-details">
                  <h5 className="address-title fw-bold">Address:</h5>
                  <p>
                    {props.mosqueInfo["Address Line 1"]},{" "}
                    {props.mosqueInfo["Address Line 2"]},
                    <br /> {props.mosqueInfo["City"]}{" "}
                    {props.mosqueInfo["Post Code"]}, UK
                    <br />
                    Phone: {props.mosqueInfo["Contact Number"]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section4;
