import { db } from "../firebase.config";
import React, { useState, useEffect } from "react";
import "./Section3.css";

const Section3 = (props) => {
  let mosqueName = props.mosqueInfo["Name"];

  let mosqueId = props.mosqueInfo["id"];
  let checkDate = props.checkDate;

  const [todayPrayer, setTodayPrayer] = useState([]);

  useEffect(() => {
    //getting current year and month
    var today = new Date();
    var month = today.getMonth() + 1;

    if (month < 10) {
      month = "0" + month;
    }

    let todayPrayertimes = [];

    //Getting mosque prayer timings for current day #####
    const todayPrayerData = async () => {
      const prayerTimes = await db
        .collection("Mosques")
        .doc(mosqueId)
        .collection("Prayer Times");

      prayerTimes.get().then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          if (doc.id === checkDate) {
            todayPrayertimes.push({ date: doc.id, ...doc.data() });
          }
          return { date: doc.id, ...doc.data() };
        });
        // console.log(todayPrayertimes);

        return setTodayPrayer(todayPrayertimes[0]);
      });
    };

    todayPrayerData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosqueId]);

  return (
    <>
      <section className="section-3">
        <div className="card hpt-content-card">
          <div className="card-header">
            <h2 className="title fw-bold">{mosqueName}</h2>
            <span className="today-date fw-bold">
              Today: {props.day} {props.thisMonth}, {props.year}
            </span>
          </div>
          <div className="card-body">
            <div className="row">
              {/* Fajr */}
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-uppercase text-center fw-bold">
                      Fajr
                    </h5>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/clock-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Start Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["fajr"]}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/mosque-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Jammat Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["fajrJamaat"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Sunrise */}
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-uppercase text-center fw-bold">
                      Sunrise
                    </h5>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/clock-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Start Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["sunrise"]}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/mosque-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Jammat Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">{"--"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Dhuhr */}
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-uppercase text-center fw-bold">
                      Dhuhr
                    </h5>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/clock-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Start Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["dhuhr"]}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/mosque-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Jammat Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["dhuhrJamaat"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Asr */}
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-uppercase text-center fw-bold">
                      Asr
                    </h5>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/clock-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Start Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["asr"]}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/mosque-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Jammat Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["asrJamaat"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Maghrib */}
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-uppercase text-center fw-bold">
                      Maghrib
                    </h5>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/clock-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Start Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["maghrib"]}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/mosque-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Jammat Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">{"--"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Isha */}
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-uppercase text-center fw-bold">
                      Isha
                    </h5>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/clock-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Start Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["isha"]}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-0">
                      <div className="col-2 text-center icon-col">
                        <img
                          src={"/mosque-icon.svg"}
                          className="icon"
                          alt={""}
                        ></img>
                      </div>
                      <div className="col-6 title-col">
                        <h6 className="title">Jammat Time :</h6>
                      </div>
                      <div className="col-4 time-stamp-col">
                        <div className="time-stamp fw-bold">
                          {todayPrayer["ishaJamaat"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
