import React from "react";
import "./Section6.css";
import Loader from "./Loader";

const Section6 = (props) => {
  const isLoading = props.isLoading;
  return (
    <>
      <section className="section-6">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 mx-auto">
              {isLoading ? (
                <>
                  <Loader />
                </>
              ) : (
                <div className="hero-wrapper">
                  <div className="row">
                    <div className="col-xl-7">
                      <h1 className="fw-bold">
                        Download Huddersfield
                        <br></br>
                        Prayer Timews Now!
                      </h1>
                      <div className="btn-group">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.hudderfieldprayer&hl=en_GB&gl=US"
                          className="store-button"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="google-play.svg" alt="" />
                        </a>
                        <a
                          href="https://apps.apple.com/us/app/id1561216898"
                          className="store-button"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="apple-store.svg" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-5">
                      <img src="download-app-hero.png" alt=""></img>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section6;
