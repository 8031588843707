import { useRef, useEffect } from "react";
import jSuites from "jsuites";

import "jsuites/dist/jsuites.css";

export default function Calendar({ options }) {
  const calendarRef = useRef(null);

  useEffect(() => {
    jSuites.calendar(calendarRef.current, options);
    // console.log(options);
  }, [options]);

  return <input ref={calendarRef} />;
}
