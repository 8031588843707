import React from "react";
import { NavLink } from "react-router-dom";
import "./Section2.css";
import Loader from "./Loader";

const Section2 = (props) => {
  const mosques = props.mosques;
  const isLoading = props.isLoading;

  return (
    <section className="section-2">
      <aside>
        {isLoading ? (
          <>
            <Loader />
            <Loader />
          </>
        ) : (
          <div className="card hpt-list-card">
            <div className="list-group list-group-flush">
              {mosques.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <NavLink
                      to={"/" + item.id}
                      className="list-group-item list-group-item-action"
                    >
                      {item.Name}
                    </NavLink>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
      </aside>
    </section>
  );
};

export default Section2;
