import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Loader = (props) => {

    return(

        <h4 className="mt-2 pt-3 mb-3 " style={{ padding: "0px 15px", width: "100%" }}>
            <Skeleton count={1} width={'100%'} />
            <Skeleton count={1} width={'70%'} />
            <Skeleton count={1} width={'100%'} />
            <Skeleton count={1} width={'50%'} />
            <Skeleton count={1} width={'70%'} />
            <Skeleton count={1} width={'100%'} />
            <Skeleton count={1} width={'70%'} />
            <Skeleton count={1} width={'100%'} />
            <Skeleton count={1} width={'70%'} />
            <Skeleton count={1} width={'100%'} />
            <Skeleton count={1} width={'50%'} />
            <Skeleton count={1} width={'70%'} />
            <Skeleton count={1} width={'100%'} />
        </h4>
    )


}

export default Loader;