import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <header>
      <nav className="navbar navbar-light navbar-expand-xl bg-white fixed-top">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src="logo-new.png" alt="" />
          </Link>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={"-1"}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <Link to="/">
                  <img src="logo-new.png" width={"150px"} alt="" />
                </Link>
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
