import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyBkWEh22bURTFOfP05CMsURVDp7WS92WIQ",
    authDomain: "huddersfield-prayer-times.firebaseapp.com",
    projectId: "huddersfield-prayer-times",
    storageBucket: "huddersfield-prayer-times.appspot.com",
    messagingSenderId: "480265120750",
    appId: "1:480265120750:web:312a959bbfe98ba5b03cff",
    measurementId: "G-9SG6TY71NJ"
  };
  
// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db };