import React from "react";
import Home from "./components/Home";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    // <Main/>

    // <Router basename="/brainbox-website" >
    <Router>
      {/* <ScrollToTop/> */}
      <Routes>
        <Route path="/" exact element={<Home />}>
          <Route path=":mosqueId" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
