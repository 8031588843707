import { db } from "../firebase.config";
import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import Loader from "./Loader";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../partials/Header/Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Footer from "../partials/Footer/Footer";

function Home() {
  const parameter = useParams();
  const MosqueId = parameter.mosqueId;

  //##### Getting current Date #####
  var today = new Date();

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var weekDay = weekDays[today.getDay()];

  var day = today.getDate();
  if (day < 10) {
    day = "0" + day;
  }

  var month = today.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var thisMonth = months[today.getMonth()];

  var year = today.getFullYear();

  var todayTimeInHour = today.getHours();
  if (todayTimeInHour < 10) {
    todayTimeInHour = "0" + todayTimeInHour;
  }

  var todayTimeInMinute = today.getMinutes();
  if (todayTimeInMinute < 10) {
    todayTimeInMinute = "0" + todayTimeInMinute;
  }

  var currentDate = day + " / " + month + " / " + year;
  var checkDate = year + "-" + month + "-" + day;

  //##### Getting all the mosques #####
  const [mosques, setMosques] = useState([]);
  const [firstMosqueId, setFirstMosqueId] = useState();
  const [SingleMosqueInfo, setSingleMosqueInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let count = 0;
    const getMosques = async () => {
      const mosquesCollectionRef = await collection(db, "Mosques");
      const data = await getDocs(mosquesCollectionRef);
      setMosques(
        data.docs.map((doc) => {
          if (count === 0) {
            setFirstMosqueId(doc.id);
          }

          count++;

          return { ...doc.data(), id: doc.id };
        })
      );
      setLoading2(false);
    };
    getMosques();
  }, []);

  useEffect(() => {
    if (MosqueId !== undefined) {
      const mosqueName = async () => {
        const mosqueName = await db.collection("Mosques").doc(MosqueId);
        mosqueName.get().then((querySnapshot) => {
          setSingleMosqueInfo({ id: MosqueId, ...querySnapshot.data() });
          setLoading(false);
        });
      };

      //todayPrayerData();
      mosqueName();
    }
  }, [MosqueId, checkDate]);

  return (
    <div className="home">
      <Header />

      <main>
        <Section1
          weekDay={weekDay}
          day={day}
          thisMonth={thisMonth}
          year={year}
          todayTimeInHour={todayTimeInHour}
          todayTimeInMinute={todayTimeInMinute}
          mosqueInfo={SingleMosqueInfo}
          currentDate={currentDate}
          checkDate={checkDate}
          isLoading={loading}
        />

        <section className="section-2-3-4">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-lg-9 col-xl-3 mx-auto">
                <Section2 mosques={mosques} isLoading={loading2} />
              </div>
              <div className="col-md-9 col-lg-9 col-xl-9 mx-auto">
                {MosqueId === undefined ? (
                  navigate("/" + firstMosqueId)
                ) : loading ? (
                  <>
                    <Loader />
                    <Loader />
                  </>
                ) : (
                  <>
                    <Section3
                      day={day}
                      thisMonth={thisMonth}
                      year={year}
                      mosqueInfo={SingleMosqueInfo}
                      currentDate={currentDate}
                      checkDate={checkDate}
                      isLoading={loading}
                    />
                    <Section4
                      mosqueInfo={SingleMosqueInfo}
                      currentDate={currentDate}
                      checkDate={checkDate}
                      isLoading={loading}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <Section5
          thisMonth={thisMonth}
          year={year}
          mosqueInfo={SingleMosqueInfo}
          currentDate={currentDate}
          checkDate={checkDate}
          isLoading={loading}
        />
        <Section6 isLoading={loading} />
      </main>

      <Footer />
    </div>
  );
}

export default Home;
