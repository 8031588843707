import React from "react";
import "./Section1.css";
import LoaderSm from "./LoaderSmall";

const Section1 = (props) => {
  const isLoading = props.isLoading;

  return (
    <section className="section-1 centered-hero-banner">
      <div className="container text-center">
        <div className="row">
          <div className="col-xl-7 mx-auto">
            <h1 className="today-date fw-bold">
              <div>Today:</div> {props.weekDay}, {props.day} {props.thisMonth}{" "}
              {props.year}
            </h1>
            <div className="time-now">
              <h1 className="heading fw-bold">Time Now</h1>
              <div className="wrapper">
                <div className="time-hour fw-bold">{props.todayTimeInHour}</div>
                <div className="time-colon fw-bold">
                  <span>:</span>
                </div>
                <div className="time-minute fw-bold">
                  {props.todayTimeInMinute}
                </div>
              </div>
            </div>
            <p className="your-location">
              <span className="fw-bold">Your Location: </span>
              {isLoading ? (
                <>
                  <LoaderSm />
                </>
              ) : (
                <>
                  {props.mosqueInfo["Address Line 1"]},{" "}
                  {props.mosqueInfo["Address Line 2"]},{" "}
                  {props.mosqueInfo["City"]}, {props.mosqueInfo["Post Code"]}
                </>
              )}
            </p>
            <div className="btn-group">
              <a
                href="https://play.google.com/store/apps/details?id=com.hudderfieldprayer&hl=en_GB&gl=US"
                className="store-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="google-play.svg" alt="" />
              </a>
              <a
                href="https://apps.apple.com/us/app/id1561216898"
                className="store-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="apple-store.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
